import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { PageContextProps, PageInfoLinkProps } from '../../types/pages'
import { ButtonProps } from '../../types/buttons'
import { ICustomerStory, ICustomerStoryCard } from '../../types/resources'
import { Layout, Seo } from '../../components/commons'
import Push from '../../components/sections/Push/Push'
import FeaturedCustomerStories from '../../components/sections/FeaturedCustomerStories'
import { CustomerStoryArticle } from '../../components/resources/customer-stories'

interface CustomerStoryQuery {
  customerStory: Pick<
    ICustomerStory,
    'id' | 'name' | 'pageInfo' | 'meta' | 'tags' | 'content' | 'push' | 'cover' | 'excerpt'
  >
  utils: {
    customerStoryLabel: string
    customerStoriesLanding: PageInfoLinkProps
    customerStoriesAllButton: Array<ButtonProps>
    customerStoriesOtherLabel: string
  }
  datoCmsGlobal: {
    postTableOfContentLabel: string
    postShareLabel: string
    postPushHeadline: string
    postPushButton: Array<ButtonProps>
  }
  otherCustomerStories: {
    nodes: Array<ICustomerStoryCard>
  }
}

const CustomerStory: React.FC<PageProps<CustomerStoryQuery, PageContextProps>> = ({ data, pageContext }) => {
  const { customerStory, utils, datoCmsGlobal, otherCustomerStories } = data

  return (
    <Layout
      pageId={pageContext.id}
      locale={pageContext.locale}
      allSlugLocales={pageContext.slugLocales}
      withoutPrefooter={customerStory.pageInfo.hidePrefooter}
    >
      <Seo
        slug={customerStory.pageInfo.slug}
        canonicalUrl={customerStory.pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={customerStory.meta}
        content={customerStory.pageInfo.seo}
      />
      <CustomerStoryArticle
        tagLabel={utils.customerStoryLabel}
        tagLink={utils.customerStoriesLanding}
        name={customerStory.name}
        cover={customerStory.cover}
        excerpt={customerStory.excerpt}
        tags={customerStory.tags}
        content={customerStory.content}
        articleSideHeadline={datoCmsGlobal.postPushHeadline}
        articleSideButton={datoCmsGlobal.postPushButton}
        articleSideShareLabel={datoCmsGlobal.postShareLabel}
        articleSideTableOfContentLabel={datoCmsGlobal.postTableOfContentLabel}
      />
      {customerStory.push && <Push id={''} model={{ apiKey: 's_push' }} sectionId={''} push={customerStory.push} />}
      {otherCustomerStories.nodes.length > 0 && (
        <FeaturedCustomerStories
          sectionId={''}
          headline={utils.customerStoriesOtherLabel}
          button={utils.customerStoriesAllButton}
          customerStories={otherCustomerStories.nodes}
          id={''}
          model={{ apiKey: 's_featured_customer_stories_list' }}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query CustomerStoryQuery($locale: String!, $id: String) {
    datoCmsGlobal(locale: $locale) {
      postTableOfContentLabel
      postShareLabel
      postPushHeadline
      postPushButton {
        ...buttonFragment
      }
    }

    utils: datoCmsResourcesGlobal(locale: $locale) {
      customerStoryLabel
      customerStoriesLanding: customerStoriesPageInfo {
        ...pageInfoLinkFragment
      }
      customerStoriesOtherLabel
      customerStoriesAllButton {
        ...buttonFragment
      }
    }

    otherCustomerStories: allDatoCmsCustomerStory(
      locale: $locale
      limit: 3
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { id: { ne: $id } }
    ) {
      nodes {
        ...customerStoryCardFragment
      }
    }

    customerStory: datoCmsCustomerStory(locale: $locale, id: { eq: $id }) {
      id
      name
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      tags {
        ...resourceTagFragment
      }
      excerpt
      cover {
        alt
        title
        gatsbyImageData(layout: CONSTRAINED, width: 800, placeholder: NONE)
      }
      content {
        value
        links
        blocks {
          __typename
          ...blockImageFragment
          ...blockVideoFragment
          ...blockGatedContentFragment
          ...buttonFragment
          ...blockTableFragment
        }
      }
      push {
        ...pushFragment
      }
    }
  }
`

export default CustomerStory
